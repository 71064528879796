<template>
  <BaseModal class="base-overlay-first main-base-modal-first">
    <div class="modal">
      <img
        alt=""
        class="header__logo"
        height="20"
        src="/logos/logo.svg"
        width="102"
      />

      <div class="modal__container">
        <p class="modal__title typography__headline3">Войдите в Donut</p>

        <SharedAuthSocialButtons :columns="2">
          <SharedAuthSocialButton
            project="crm"
            :redirect-to="authSocialRedirectUrl"
            social="vk"
          />

          <SharedAuthSocialButton
            project="crm"
            :redirect-to="authSocialRedirectUrl"
            social="yandex"
          />

          <SharedAuthSocialButton
            project="crm"
            :redirect-to="authSocialRedirectUrl"
            social="google"
          />

          <SharedAuthSocialButton
            project="crm"
            :redirect-to="authSocialRedirectUrl"
            social="mailru"
          />
        </SharedAuthSocialButtons>

        <p class="modal__additional typography__body2">или с помощью</p>

        <form
          class="modal__container"
          @submit="(event) => handleSubmit(event)"
        >
          <div class="modal__wrapper">
            <BaseInput2
              v-bind="fields.email[1].value"
              id="login-email"
              v-model="fields.email[0].value"
              autocomplete="email"
              class="base-input-first"
              :error="errors.email"
              name="email"
              placeholder="Email"
              type="email"
            />

            <!-- TODO: Заменить на BaseInputPassword -->
            <BaseInput2
              v-model="fields.password[0].value"
              v-bind="fields.password[1].value"
              autocomplete="password"
              class="base-input-first"
              :error="errors.password"
              name="password"
              placeholder="Пароль"
              type="password"
            />

            <BaseModalButton
              class="ui-link-secondary ui-link-small"
              :modal="MODAL.AuthPasswordReset"
            >
              Не помню пароль
            </BaseModalButton>
          </div>

          <div class="modal__buttons">
            <BaseButton2
              class="base-button-extra-big base-button-first base-button-round modal__button"
              class-loader="base-loader-second"
              :loading
              type="submit"
            >
              Войти
            </BaseButton2>

            <p class="typography__body2">
              Ещё нет аккаунта?

              <BaseModalButton
                class="ui-link-secondary"
                :modal="MODAL.AuthRegistration"
              >
                Зарегистрируйтесь
              </BaseModalButton>
            </p>
          </div>
        </form>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts" setup>
const { errors, fields, handleSubmit, loading } = useAuthLoginForm();
const url = useRequestURL();
const authSocialRedirectUrl = `${url.origin}/client/projects`;
</script>

<style scoped>
.modal {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

@media (--desktop) {
  .modal {
    row-gap: 40px;
  }
}

.modal__title {
  color: var(--grey-1-5);
}

.modal__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
}

.modal__additional {
  color: var(--grey-3);
}

.modal__container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.modal__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  color: var(--grey-1-5);
}

@media (--desktop) {
  .modal__buttons {
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
  }
}

.modal__button {
  width: 100%;
}

@media (--desktop) {
  .modal__button {
    width: auto;
  }
}
</style>
